<template>
  <v-card class="mx-auto mt-5" elevation="4" max-width="344">
    <v-card-title class="justify-center">Password Reset</v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-form ref="form" v-model="valid" v-on:submit.prevent>
        <v-row>
          <v-col>
            <v-text-field
              v-model="email"
              label="E-mail"
              placeholder="E-mail"
              hint="Enter e-mail to recieve a link to reset your password"
              solo
              :rules="req"
              :disabled="loading"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn
        color="primary"
        style="width:100%"
        @click="submit"
        :disabled="loading"
        :loading="loading"
      >
        Send Reset Link
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<style scoped>
.v-card__title {
  text-align: center;
  width: 100%;
}
</style>
<script>
import hasuraService from "@/services/hasura.service.js";

export default {
  name: "PasswordReset",
  mixins: [hasuraService],
  methods: {
    submit: async function() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        let r = await this.passwordResetOne(this.email);
        console.log(r);
        this.$store.commit(
          "set_snackbar",
          "If that email address is associated with an account, an email will be sent to it with a password reset link."
        );
        this.loading = false;
      } else {
        this.$store.commit("set_snackbar", "Please fill out the email field.");
      }
    },
  },
  data: () => {
    return {
      email: "",
      valid: true,
      loading: false,
      req: [(v) => !!v || "Field is required"],
    };
  },
};
</script>
